import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: November 29, 2022 </p>

      <p>
      If you have any questions about this Privacy Policy or the manner in which we or our Third-Party Agents treat your Personal Information, the practices of the Site, your dealings with the Chefry Platform, or if you have technical problems, you may contact us here.

      Chefry's staff will respond to all mail or email from Users with questions about privacy, including the types of Personal Information stored on the Chefry databases, and requests to know, delete or rectify such Personal Information.
      </p>

      <h2>1. Introduction</h2>
      <p>
      This Global Privacy Policy (“Privacy Policy”) describes how Chefry, Inc. and its affiliates 
      will gather, use, and maintain your Personal Information on the Chefry Platform. It will also 
      explain your legal rights with respect to that information.
      
      By using the Chefry Platform, you confirm that you have read and understand this Privacy Policy,
       and our Global Terms of Service (together referred to herein as the “Agreement”). The Agreement
        governs the use of the Chefry Platform. Chefry will collect, use, and maintain information
         consistent with the Agreement.

      If you are a California resident or data subject in Europe, please see the “Additional Disclosures 
      for California Residents” and “Additional Disclosures for Data Subjects in the European Economic Area
      (EEA), Switzerland, and the UK” sections, respectively. If you have any questions or wish to
      exercise your rights and choices, please contact us as set out in the “Contact Us” section below.
      </p>

      <h2>2. General Terms</h2>
      <p>
      In this Privacy Policy:
      Chefry, Inc. may be referred to as "Chefry," “we,” “our,” or “us.”
      We call a user of the Chefry Platform “User,” “Users,” “you,” “your,” or “Client” and “Chef,” as appropriate.
      The community platform that we offer at our website (www.Chefry.io and local variants, including www.Chefry.co.uk, www.Chefry.ca, and www.Chefry.fr, www.Chefry.de, and www.Chefry.it) is referred to as the “Site(s).”
      The mobile applications (whether on iOS or Android) are referred to as the “Apps.”
      Chefry’s Sites, Apps and related services, information and communications are collectively referred to as the “Chefry Platform.”
      “Terms of Service” refers to our Global Terms of Service, which can be found here. This Privacy Policy is incorporated into, and considered a part of, the Terms of Service.
      </p>

      <h2>3. Information Collection
        Information you provide to Chefry</h2>
      <p>
      Chefry collects certain personally identifiable information about you, including information that can identify, relate to, describe, be associated with you, or is reasonably capable of being associated with you (“Personal Information”). Examples of Personal Information that Chefry collects include but are not limited to:

  Contact Information. This may include your first and last name, postal address, telephone number, and email address.

  Billing Data, including your payment instrument number (such as a credit or debit card number), expiration date, and security code as necessary to process your payments.

  Identity Information. If you are a Chef, we may collect Personal Information, such as your date of birth and address, national identification number if applicable, together with the result of basic criminal record checks as provided by you, or by our Third-Party Agents (as defined below), as applicable and to the extent permitted by law in your jurisdiction, as well as such information as may be needed to validate your identity.

  Financial Information. To help Chefs set up a payment account and help Clients make payments to Chefs and pay fees to Chefry, we may collect financial information, including credit card number, bank routing numbers, tax information, taxpayer identification number, and other payment information, as applicable. We use Financial Information in order to operate the Chefry Platform and to ensure that Chefs are paid by Clients. We do this as necessary for our legitimate interests in providing our platform and services and to fulfill our contracts with Users. To keep your financial data secure, we have contracted with a third party to maintain and process your payment information.

  Promotional Information. Certain offerings of the Chefry Platform, such as newsletters, surveys, contests, and the like are optional and so you are not required to enter them or to give us your data in connection with them. Where you do consent to take advantage of Chefry Platform offerings, we will use your data to (as applicable) send you newsletters and other communications that are tailored based on information we have about you, or to operate and manage the survey, contest or similar offering in connection with our legitimate interest in promoting our business and the Chefry Platform. To opt-out of receiving marketing communications from us, please see Section 7, Your Rights and Choices.

  Employment Information. We collect employment and education history, transcripts, writing samples, and references as necessary to consider your job application for open positions.

  Content Information. You also may choose to send Chefry Personal Information in an email or chat message containing inquiries about the Chefry Platform and we use this Information in order to help us respond to your inquiry. We also collect content within any messages you exchange with other Users through the Chefry Platform (such as through our chat functionality).

  We require that you furnish certain information when you register an account with us in order to provide services through the Chefry Platform. For example, if you are a Client, we collect your first and last name, email address, and your zip or postal code in order to create and administer your Chefry account. We also collect additional information in order to facilitate your booking request, such as information about the task you are seeking, the time, date and location of the task, and Billing Data. If you are a Chef, we collect your first and last name, email address, mobile phone number and zip or postal code in order to create and administer your Chefry account and facilitate communications between you and your Clients through the Chefry Platform. We also collect information about your tasks, rates, and skills, as well as Identity Information and Financial Information.

  Third Party Information. If you registered through a co-branded version of our Chefry Platform through one of our Partners (as defined below) or IKEA, they may provide us with basic information such as your name, address, email, and purchase history related to your Task needs. We may receive additional information about you, such as demographic data, Financial Information, or fraud detection information, from Third-Party Agents (as defined below) and combine it with other information that we have about you, to the extent permitted by law, in order to comply with our legal obligations and for the legitimate interest in improving the Chefry Platform. Chefry may work with Third-Party Agents to perform identity checks and criminal background checks on Chefs, if applicable and permitted by local law, in order to advance our legitimate interests in ensuring the safety of our Users and maintaining the integrity of the Chefry Platform. Chefry Platform
      </p>

      <h2>4. Information Chefry Collects Automatically</h2>
      <p>
                  We automatically collect certain information when you use the Chefry Platform. The categories of information we automatically collect and have collected, including in the last 12 months, are as follows:

            Service Use Data, including data about features you use, pages you visit, emails and advertisements you view, portions of the Chefry Platform that you view and interact with, the time of day you browse, and your referring and exiting pages.

            Device Data, including data about the type of device or browser you use, your device’s operating system, your internet service provider, your device’s regional and language settings, and device identifiers such as IP address and Ad Id. When you visit and interact with the Chefry Platform, Chefry may collect certain information automatically through cookies or other technologies, including, but not limited to, the type of computer or mobile device you use, your mobile device’s unique device ID, the IP address of your computer or mobile device, your operating system, the type(s) of internet browser(s) you use, and information about the way you use the Chefry Platform (“Device Information”). We may use Device Information to monitor the geographic regions from which Users navigate the Chefry Platform, and for security and fraud prevention purposes. Use of any IP-masking technologies or similar technologies (like the TOR network) may render portions of the Chefry Platform unavailable and are forbidden on the Chefry Platform.

            Location Data, including imprecise location data (such as location derived from an IP address or data that indicates a city or postal code level), and, with your consent, precise location data (such as latitude/longitude data). When you visit the Chefry Platform via a native mobile application, we use, with your consent when required under applicable law, GPS technology (or other similar technology) to determine your current location in order to determine the city you are located in and display a relevant location map. We will not share your current location obtained in this manner with other Users.

            Cookies and similar technologies are described in our Cookie Policy, which sets out the different categories of cookies and similar technologies that the Chefry Platform uses and why we use them.
      </p>

      <h2>5. Chefry's Use of Information
</h2>
      <p>
      We collect and use information for business and commercial purposes in accordance with the practices described in this Privacy Policy. Our business purposes for collecting and using information include:

      To operate and make available the Chefry Platform. We use your data to connect you with other Users to enable the posting of, selection for, completion of, and payment for Tasks, in order to fulfill our contracts with Users;
      For billing and fraud prevention, on the basis of our legitimate interests in ensuring a safe and secure environment in which Clients and Chefs can meet and conduct business, and in order to comply with our legal obligations;
      To conduct identification and criminal background checks, if applicable and to the extent permitted by local law, on Users, in order to advance our legitimate interests as well as for the substantial public interest of ensuring the safety of our Users both online and offline, preventing or detecting unlawful acts, protecting the public against dishonesty, and maintaining the integrity of the Chefry Platform given that Chefs often interact directly with Clients and may enter their homes;
      To analyze Chefry Platform usage as necessary for our legitimate interest in improving the Chefry Platform to grow our business;
      To contact you and deliver (via email, SMS, push notifications, or otherwise) transactional information, administrative notices, marketing notifications, offers and communications relevant to your use of the Chefry Platform, with your consent when required under applicable law, as necessary for our legitimate interests in proper communication and engagement with our Users, and in promoting our business;
      To provide you with customer support in order to fulfill our contracts with Users;
      For internal market research for our legitimate interest in improving the Chefry Platform to grow our business;
      For troubleshooting problems for our legitimate interests in ensuring a safe and secure environment in which Users can meet;
      To assist Users in the resolution of complaints and disputes between them, as necessary for our legitimate interests in facilitating good relations among Users;
      To enforce our Terms of Service and our legitimate interests in ensuring our Agreement is complied with; and
      As otherwise set forth in the Agreement.
      Interest-Based Advertising. Ads are a standard part of user experience on the Internet, and Chefry believes that targeted advertising enhances this experience. Chefry and affiliate third parties may use cookies and other technologies to place ads where they believe interested Users will see them. In addition to banner ads, Chefry may advertise products, companies and events that we think might interest you through the email address and/or phone number you provide. We may incorporate Tracking Technologies on the Chefry Platform (including our website and emails) as well as into our ads displayed on other websites and services. Some of these Tracking Technologies may track your activities across time and services for purposes of associating the different devices you use, and delivering relevant ads and/or other content to you (“Interest-Based Advertising”).

      For more information and to understand your choices regarding third-party ads, please see our Cookie Policy. Advertising and marketing is carried out as necessary for our legitimate interests in providing an engaging and relevant experience, promoting our services, and growing our business.

      Analytics and Market Analysis. Chefry may analyze information (“Market Analysis”) as necessary for our legitimate interests in providing an engaging and relevant experience, and promoting and growing the Chefry Platform.

      Chefry uses information to offer services to Users who express an interest in these services, through a poll for example, or to Users who can be presumed to have an interest based on results from our Market Analysis. We do this as necessary for our legitimate interests in providing an engaging and relevant experience, promoting our services, and growing our business.

      Cell Phone Numbers. Chefry may use your cell phone number to call or send recurring text messages to you, using an autodialer or prerecorded voice, in order to provide you notifications about Tasks, for marketing purposes (with your consent where required by law), and to administer the Chefry Platform. If you would like more information about our policy, or how to opt out, please review the “Your Rights and Choices” section below or Section 9 of our Terms of Service. You may be liable for standard SMS and per-minute charges by your mobile carrier. Chefry may also message you via push notifications (with your consent when required under applicable law), which you can opt-out of on your mobile device. Data rates may apply.

      Call recordings. Calls to or from Chefry or its Third Party Agents may be monitored and recorded for the purposes of quality control and training. Such calls may contain Personal Information.
      </p>

    
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
