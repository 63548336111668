import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemUnitPriceMaybe = props => {
  const { transaction, unitType, intl, is_catreen, is_ghost_kitchen, pageData } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.pricePerNight'
    : isDaily
    ? 'BookingBreakdown.pricePerDay'
    : is_catreen
    ? 'BookingBreakdown.pricePerpersion'
    : is_ghost_kitchen
    ? 'BookingBreakdown.pricePerghostkitchen'
    : 'BookingBreakdown.pricePerQuantity';

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const per_person = pageData?.listing?.attributes?.publicData?.per_person;
  const ghost_kitchen = pageData?.listing?.attributes?.publicData?.ghost_kitchen;

  const formattedUnitPrice =
    pageData?.bookingData?.host_type === 'catering'
      ? formatMoney(intl, new Money(per_person.amount, per_person.currency))
      : pageData?.bookingData?.host_type === 'ghost_kitchen'
      ? formatMoney(intl, new Money(ghost_kitchen.amount, ghost_kitchen.currency))
      : unitPurchase
      ? formatMoney(intl, unitPurchase.unitPrice)
      : null;

  // unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;

  return formattedUnitPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} />
      </span>
      <span className={css.itemValue}>{formattedUnitPrice}</span>
    </div>
  ) : null;
};

LineItemUnitPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPriceMaybe;
