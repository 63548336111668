import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: November 29, 2022</p>

      <p>
       <h1> Thank you for using Chefry! </h1>
      </p>

      <h2>General Terms</h2>
      <p>
      In this Privacy Policy:
      Chefry, Inc. may be referred to as "Chefry," “we,” “our,” or “us.”
      We call a user of the Chefry Platform “User,” “Users,” “you,” “your,” or “Client” and “Chef,” as appropriate.
      The community platform that we offer at our website (www.Chefry.io and local variants, including www.Chefry.co.uk, www.Chefry.ca, and www.Chefry.fr, www.Chefry.de, and www.Chefry.it) is referred to as the “Site(s).”
      The mobile applications (whether on iOS or Android) are referred to as the “Apps.”
      Chefry’s Sites, Apps and related services, information and communications are collectively referred to as the “Chefry Platform.”
      “Terms of Service” refers to our Global Terms of Service, which can be found here. This Privacy Policy is incorporated into, and considered a part of, the Terms of Service.
      </p>

      <h2>Terms Of Service</h2>
      <p>
      These Terms of Service constitute a legally binding agreement between you and Chefry, Inc. ( “Chefry” ) governing your use of the Chefry Platform. Chefry’s websites (including www.Chefry.io , www.Chefry.co.uk , www.Chefry.ca , www.Chefry.fr , www.Chefry.de , www.Chefry.es , www.Chefry.pt , and www.Chefry.it ) (the “Sites” ), mobile applications (the “Apps” ), and related services, information and communications are collectively referred to as the “Chefry Platform.”

The use of all personal data you submit to the Chefry Platform or which we collect about you is governed by our Global Privacy Policy ( “Privacy Policy” ). A copy of our Privacy Policy is available here . You acknowledge that by using the Chefry Platform you have reviewed the Privacy Policy.

Your consent at registration and continued use of the Chefry Platform constitutes your acceptance of and agreement to all of the terms and conditions in these Global Terms of Service, the Privacy Policy , and the Chefry Happiness Pledge  ( the “Happiness Pledge” ) for the country in which the Task is performed, as well as any future amendments and additions to this Agreement (as defined below) we may publish from time to time. If any future changes to this Agreement are unacceptable to you or cause you to no longer be in compliance with this Agreement, you must deactivate your account, and immediately stop using the Chefry Platform.

The Privacy Policy and the Happiness Pledge for the country in which the Task is performed are incorporated by reference into these Terms of Service and together form and are hereinafter referred to as the “Agreement” .

IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AND ABIDE BY ITS TERMS, YOU MAY NOT USE OR ACCESS THE Chefry PLATFORM.

PLEASE NOTE: SECTION 28, WHICH CONTAINS JURISDICTION-SPECIFIC PROVISIONS, SPECIFIES HOW USERS AND Chefry RESOLVE DISPUTES. FOR U.S. AND CANADIAN USERS, SECTION 28 CONTAINS AN ARBITRATION AGREEMENT. THIS ARBITRATION AGREEMENT, WITH LIMITED EXCEPTION, REQUIRES YOU TO SUBMIT DISPUTES AND CLAIMS YOU HAVE AGAINST Chefry TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS. PLEASE READ IT CAREFULLY AS IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING, IF APPLICABLE, YOUR RIGHT TO OPT OUT OF ARBITRATION. FOR OUR UNITED KINGDOM AND EUROPEAN USERS, PLEASE REFER TO YOUR JURISDICTION-SPECIFIC PROVISIONS IN SECTION 28 REGARDING DISPUTE RESOLUTION.

BY ACKNOWLEDGING THE TERMS OF SERVICE AND/OR USING THE Chefry PLATFORM, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE, WITHOUT LIMITATION OR QUALIFICATION, TO BE BOUND BY THIS AGREEMENT (INCLUDING, AS APPLICABLE FOR U.S AND CANADIAN USERS, THE DISPUTE RESOLUTION AND ARBITRATION AGREEMENT) AND YOU ACCEPT ALL OF ITS TERMS.

Your use of the Chefry Platform in a country other than the country in which you register constitutes your acceptance of and agreement to all of the terms and conditions in the Agreement for that country.

A few highlights of these Terms of Service include:

You must be at least the legally required age in the jurisdiction in which you reside (18 years old for U.S. based users), and otherwise capable of entering into binding contracts, in order to use or access the Chefry Platform (Section 2).
Your agreement that the technology for the Chefry Platform is provided “as is” and without warranty (Section 17).
Your agreement that Chefry provides no warranty and has no liability regarding User action on the Chefry Platform or the performance of Tasks (Section 17).
Your acknowledgment and agreement that Chefry does not supervise, scope, direct, control, or monitor a Tasker’s work and the Tasks performed (Section 1).
Your acknowledgement and agreement that Clients are solely responsible for determining if the Tasker they hire is qualified to perform the Task (Sections 1 and 23).
Your acknowledgement and agreement that Taskers are independent contractors of Clients and not employees, independent contractors or service providers of Chefry (Section 1).
Your agreement to hold harmless and indemnify Chefry from claims due to your use of or inability to use the Chefry Platform or content submitted from your account to the Chefry Platform (Section 18).
For U.S. and Canadian Users, your agreement to arbitrate disputes with Chefry on an individual basis to the fullest extent permitted by applicable law, with other jurisdiction-specific means of dispute resolution set forth for United Kingdom and European Users, all in Section 28.
1. The Chefry Platform Connects Taskers and Clients
The Chefry Platform is a web- and app-based two-sided marketplace which enables connections between Clients and Taskers. “Clients”  are individuals and/or businesses seeking to obtain short-term services ( “Tasks” ) from Taskers and are therefore clients of Taskers, and “Taskers”  are businesses seeking to perform Tasks for Clients. Clients and Taskers together are hereinafter referred to as “Users.”  If you agree on the terms of a Task with another User, you and such other User form a Service Agreement directly between the two of you as set forth in more detail in Section 3 below.

TASKERS ARE INDEPENDENT BUSINESS OWNERS. TASKERS ARE INDEPENDENT CONTRACTORS OF CLIENTS AND NOT EMPLOYEES, PARTNERS, REPRESENTATIVES, AGENTS, JOINT VENTURERS, INDEPENDENT CONTRACTORS OR FRANCHISEES OF Chefry. Chefry DOES NOT PERFORM TASKS AND DOES NOT EMPLOY INDIVIDUALS TO PERFORM TASKS. BY CONNECTING PEOPLE AND BUSINESSES SEEKING SERVICES WITH SERVICE PROVIDERS, Chefry OPERATES AS AN ONLINE MARKETPLACE THAT CONNECTS CLIENTS WITH SERVICE PROVIDERS (TASKERS) WHO WISH TO PERFORM A VARIETY OF TASKS.

USERS HEREBY ACKNOWLEDGE THAT Chefry DOES NOT SUPERVISE, SCOPE, DIRECT, CONTROL OR MONITOR A TASKER’S WORK AND EXPRESSLY DISCLAIMS (TO THE EXTENT PERMITTED BY LAW) ANY RESPONSIBILITY AND LIABILITY FOR THE WORK PERFORMED AND THE TASKS IN ANY MANNER, INCLUDING BUT NOT LIMITED TO A WARRANTY OR CONDITION OF GOOD AND WORKMANLIKE SERVICES, WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, STATUTE, ORDINANCE, REGULATION, OR CODE.

Any reference on the Chefry Platform to a Tasker being licensed or credentialed in some manner, or "badged," “reliable,” “reliability rate,” “elite,” “great value,” "background checked," “vetted” (or similar language) designations indicates only that the Tasker has completed a relevant account registration process or met certain criteria and does not represent anything else. Any such description is not an endorsement, certification or guarantee by Chefry of such Tasker’s skills or qualifications or whether they are licensed, insured, trustworthy, safe or suitable. Instead, any such description is intended to be useful information for Clients to evaluate when they make their own decisions about the identity and suitability of Taskers whom they select, interact, or contract with via the Chefry Platform.

The Chefry Platform enables connections between Users for the fulfillment of Tasks. Chefry is not responsible for the performance or communications of Users, nor does it have control over the quality, timing, legality, failure to provide, or any other aspect whatsoever of Tasks, Taskers, or Clients, nor of the integrity, responsibility, competence, qualifications, or any of the actions or omissions whatsoever of any Users, or of any ratings or reviews provided by Users with respect to each other. Chefry makes no warranties or representations about the suitability, reliability, timeliness, or accuracy of the Tasks requested or services provided by, or the communications of or between, Users identified through the Chefry Platform, whether in public or private, via on- or off-line interactions, or otherwise howsoever.

2. Tasker Background Checks and User Representations and Warranties
Tasker Background Checks
Taskers may be subject to a review process before they can register for and during their use of the Chefry Platform, which may include but is not limited to identity verification and criminal background checks, using third party services as appropriate ( “Background Checks” ). Although Chefry may perform Background Checks, Chefry cannot confirm that any User is who they claim to be, and Chefry cannot and does not assume any responsibility for the accuracy or reliability of Background Check information.

When interacting with other Users, you should exercise caution and common sense to protect your personal safety, data, and property, just as you would when interacting with other persons whom you don’t know. Chefry will not be liable for any false or misleading statements made by Users of the Chefry Platform.

NEITHER Chefry, NOR ITS PARENTS, AFFILIATES OR LICENSORS, INCLUDING THEIR RESPECTIVE DIRECTORS, OFFICERS, SHAREHOLDERS, AGENTS, INVESTORS, SUBSIDIARIES, ATTORNEYS, REPRESENTATIVES, INSURERS, EMPLOYEES, SUCCESSORS AND ASSIGNS (COLLECTIVELY REFERRED TO AS “AFFILIATES” ) IS RESPONSIBLE OR LIABLE FOR THE CONDUCT, ACTS, OR OMISSIONS, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE Chefry PLATFORM AND, TO THE EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE Chefry AND AFFILIATES FROM ANY AND ALL LIABILITY, CLAIMS, DEMANDS, OR DAMAGES OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE Chefry PLATFORM.

User Representations and Warranties
All Users represent and warrant that:

You are at least of the legally required age in the jurisdiction in which you reside (18 years of age or older for U.S. based users), and are otherwise capable of entering into binding contracts;
You have the right, authority and capacity to enter into this Agreement and to abide by the terms and conditions of this Agreement, and that you will so abide;
You have read, understand, and agree to be bound by these Terms of Service, the Privacy Policy, and the Happiness Pledge applicable to the country where the Task is performed;
Tasks shall only be performed in a country where the Chefry Platform has a presence.
You will respect the privacy (including without limitation private, family and home life), property, and data protection rights of Users and will not record (whether video or audio or otherwise) any Task or any interaction by or with any User and/or Chefry in connection with the Chefry Platform without the prior written consent of Chefry and/or the relevant User, as applicable;
You will fulfill the commitments you make to other Users, communicate clearly and promptly through the chat thread, be present and/or available at the time you agree upon with your Tasker or Client as the case may be, and only utilize the third party payment service provider specified or approved by Chefry to make or receive payment for services provided through the Chefry Platform ( the “PSP” );
You will act professionally and responsibly in your interactions with other Users;
You will use your real name or business name on your profile;
When using or accessing the Chefry Platform, you will act in accordance with all applicable local, state, provincial, national, or international law or custom and in good faith;
You will not use the Chefry Platform for the purchase or delivery of alcohol, or any other controlled or illegal substances or services.
Where you enter into this Agreement on behalf of a company or other organization, you represent and warrant that you have authority to act on behalf of that entity and to bind that entity to this Agreement.
Other than as fully and promptly disclosed in writing to Chefry, you do not have any motivation, status, or interest that Chefry may reasonably wish to know about in connection with the Chefry Platform, including without limitation, if you are using or will or intend to use the Chefry Platform for any journalistic, academic, investigative, or unlawful purpose.
Taskers additionally represent and warrant that:

When using the Chefry Platform, you are operating as a sole proprietor, partnership, limited liability company, limited liability partnership, corporation or other business entity;
You are customarily engaged in an independently established business of the same nature as the services performed for Clients through the Chefry Platform, and maintain an independent clientele;
You have the unrestricted right to work in the jurisdiction in which you will be performing Tasks;
If the Task is performed in a jurisdiction that requires you to have a business license or business tax registration, you have the required business license or business tax registration;
You are responsible for identifying and obtaining any required licenses, permits, or registrations before offering services and undertaking Tasks (including but not limited to a state contractor’s license pursuant to California Business and Professions Code section 7000 et seq., if such license is applicable to the Task you are performing);
You have any and all insurance required to operate your business and provide your services;
You will use your real name or business name and an up-to-date photo on your profile;
You will honor your commitments to other Users on the Platform, including by responding to invitations promptly; performing the Task(s) as agreed upon with your Client; and providing timely, high-quality services to your Clients;
You will only offer and provide services for which you have the necessary skills and expertise, and provide those services safely and in accordance with all applicable laws.
3. Contract between Clients and Taskers
You acknowledge and agree that a legally binding contract with another User (the “ Service Agreement ” ) is formed when you and that User agree on the terms of a Task. The terms of the Service Agreement include the terms set forth in this Section 3, the engagement terms proposed and accepted on the Chefry Platform, and any other contractual terms accepted by both the Tasker and their Client to the extent such terms do not conflict with the terms in this Agreement, including this Section 3, and do not expand Chefry’s obligations or restrict Chefry’s rights under this Agreement. Chefry is not a party to any Service Agreement and the formation of a Service Agreement will not, under any circumstances, create an employment or other service relationship between Chefry and the Tasker, nor will it create an employment relationship between the Client and the Tasker. Users do not have authority to enter into written or oral — whether implied or express — contracts on behalf of Chefry.

Where approved in advance by the Client,  the Tasker is not obligated to personally perform the Task. Taskers may engage assistants, helpers, subcontractors or other personnel (collectively “Tasker Assistants” ). For safety reasons, such Tasker Assistants shall have been registered through the Chefry Platform. A Tasker’s failure to comply with this provision shall be a violation of these Terms of Service and could lead to removal from the Chefry Platform. The Tasker assumes full and sole responsibility for the acts and omissions of such Tasker Assistants and is fully responsible for the lawful payment of all compensation, benefits and expenses of Tasker Assistants, if any, and for all required and applicable tax withholdings as to such Tasker Assistants. Clients are responsible for confirming with their Tasker that any Tasker Assistants are registered Taskers on the Chefry Platform.

While using the Chefry Platform, Clients, in their sole discretion, determine whether they will be present or not when a Task is performed. Clients agree that if someone other than them books the Task on their behalf or is present in their stead when the Task is performed, they are appointing that person as their agent ( “ Client’s Agent ” ) and the Tasker may take and follow direction from the Client’s Agent as if such direction was given from the Client him- or herself. If you are a Client’s Agent and accessing and using the Chefry Platform on behalf of a Client, you represent that you have the authority to act as their agent and to bind that person as the principal to all provisions of the Terms of Service. Client’s Agent may authorize any applicable waiver of the Client’s scoping, direction, or instruction of the Tasker’s work or performance of the Task in the Client’s stead. Client agrees that such direction and/or waiver is valid against Client and Client’s Agent, and Client shall be responsible and liable for all such direction and/or waiver as if made by Client him or herself. Client assumes full and sole responsibility for the acts and omissions of Client’s Agents. Client’s Agents are deemed third parties for the purposes of the Happiness Pledge and are thereby excluded from it.

The Client shall pay their Tasker(s) in full for all Task services via the PSP as indicated on the Chefry Platform, at the rates agreed to by the parties in the Service Agreement. Each User agrees to comply with the Service Agreement and this Agreement during the engagement, performance and completion of a Task.

4. Billing and Payment
Users of the Chefry Platform contract for Tasks directly with other Users. Chefry will not be a party to any contracts for Tasks or services. Client, and not Chefry, is responsible for p ayment for all Task services through the Chefry Platform.  Such payment must be made via the PSP.  Chefry is not obligated to compensate Tasker for Client’s failure to pay for services.

The Task Payment, service charge, and Trust & Support fee must be paid through the PSP.  Clients on the Chefry Platform will be required to provide their payment method details to Chefry and the PSP. Taskers are responsible for accurately invoicing their Clients within 24 hours of the work being performed, even if the Task is not completed in its entirety or is designated as “ongoing”. Clients will be responsible for paying the invoice(s) for each Task (the “Invoice(s)” ), which will include (a) the pricing terms of the Task provided by the Tasker and agreed upon by the parties ( “Task Payment” ), (b) any out of pocket expenses agreed upon by the parties and submitted by the Tasker in connection with the Task,  (c) the service charge Chefry assesses to the Client for access to and information regarding Taskers, and (d) the Trust & Support Fee Chefry assesses to the Client for  customer support, services in support of the Happiness Pledge, and other various other services, or (e) cancellation charges, if applicable. In addition, a tip or gratuity, as applicable, may be added by the Client or at the Client’s direction to the Invoice(s), and all such tips or gratuities shall go directly to the Tasker. Clients may also be charged credit card processing fees equal to 3% of the aggregate expense amount if expenses related to a Task individually, or Tasks in the aggregate over a 30-day period, exceed $300 / 300£ / 300€, as applicable in your country . Taskers will be responsible for paying (i) registration fees, if applicable, and (ii) repayment of erroneous payments.

Taskers will be required to set up an account with the PSP, which may require any or all of the following: registration with the PSP, consent to the terms of service of the PSP, and completion of a vetting process and/or account validation at the request of the PSP. By accepting these Terms of Service, each Tasker agrees that they have downloaded or printed, and reviewed and agreed to, the PSP Services Agreement (the “PSP Services Agreement” ). Please note that Chefry is not a party to the PSP Services Agreement and that you, the PSP and any other parties listed in the PSP Services Agreement are the parties to the PSP Services Agreement and that Chefry has no obligations, responsibility or liability to any Tasker or other party under the PSP Services Agreement.

To help prevent fraud and safeguard User information from the risk of unauthorized access, Chefry and/or the PSP may validate an account before activation and prior to each booking. As part of the validation process, temporary charges may be placed on the account associated with the User and then refunded within 1-5 business days.   This temporary charge may vary depending on the estimated value of the Task and expenses.

When Client receives confirmation through the Chefry Platform or via email that a Task has been completed, Client automatically authorizes the PSP to process the Invoice(s). Clients  may be billed a one-hour cancellation charge as damages at the Tasker’s hourly rate through the PSP if you book a Task, but cancel it before the scheduled time for performance  (for users in Germany, please refer to the Jurisdiction-specific provisions).

Chefry reserves the right (but not the obligation) upon request from Client or Tasker, or upon notice of any potential fraud, unauthorized charges or other misuse of the Chefry Platform, to place on hold any Task Payment, out of pocket expenses, and/or tip or gratuity (if applicable), or refund or provide credits, or arrange for the PSP to do so.

Users of the Chefry Platform may be liable for any taxes or similar charges (including VAT, if applicable in the country where the Task is performed) required to be collected and/or paid on the Tasks and/or fees provided under the Agreement.

In certain jurisdictions, applicable rules may require that we collect or report tax and/or revenue information about you.  You agree that Chefry may issue on your behalf receipts or similar documentation to facilitate accurate tax reporting.
      </p>

     
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
