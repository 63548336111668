import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-hollywood',
    predictionPlace: {
      address: 'Hollywood, California, USA',
      bounds: new LatLngBounds(
        new LatLng(40.917576401307, -73.7008392055224),
        new LatLng(34.090703,-118.370420)
      ),
    },
  },
  {
    id: 'default-los-angeles',
    predictionPlace: {
      address: 'Los Angeles, California, USA',
      bounds: new LatLngBounds(
        new LatLng(34.098340,-118.326740),
        new LatLng(33.9018913203336, -118.521456965901)
      ),
    },
  },
  {
    id: 'default-san-francisco',
    predictionPlace: {
      address: 'San Francisco, California, USA',
      bounds: new LatLngBounds(
        new LatLng(37.8324430069081, -122.354995082683),
        new LatLng(37.6044780500533, -122.517910874663)
      ),
    },
  },
  {
    id: 'default-calabasas',
    predictionPlace: {
      address: 'Calabasas, California, USA',
      bounds: new LatLngBounds(
        new LatLng(34.152831,-118.647594),
        new LatLng(34.162769,-118.702310)
      ),
    },
  },
  {
    id: 'default-portland',
    predictionPlace: {
      address: 'Pasadena, California, USA',
      bounds: new LatLngBounds(
        new LatLng(34.147643,-118.142959),
        new LatLng(34.135879,-118.132248)
      ),
    },
  },
];
export default defaultLocations;
