import { storableError } from '../../util/errors';
import { Realm } from '../../util/sdkLoader';
// ================ Action types ================ //

export const RESET_PASSWORD_REQUEST = 'app/PasswordResetPage/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/PasswordResetPage/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/PasswordResetPage/RESET_PASSWORD_ERROR';

// ================ Reducer ================ //

const initialState = {
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case RESET_PASSWORD_REQUEST:
      return { ...state, resetPasswordInProgress: true, resetPasswordError: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordInProgress: false };
    case RESET_PASSWORD_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, resetPasswordInProgress: false, resetPasswordError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const resetPasswordRequest = () => ({ type: RESET_PASSWORD_REQUEST });

export const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });

export const resetPasswordError = e => ({
  type: RESET_PASSWORD_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const resetPassword = (email, passwordResetToken, newPassword) => async (
  dispatch,
  getState,
  { sdk, app }
) => {
  dispatch(resetPasswordRequest());
  const params = { email, passwordResetToken, newPassword };
  const credentials = Realm.Credentials.apiKey(process.env.REACT_APP_API_KEY);
  await app.logIn(credentials);
  return app.currentUser.functions
    .resetPassword(params)
    .then(() => {
      app.currentUser.logOut();
      dispatch(resetPasswordSuccess());
    })
    .catch(e => {
      app.currentUser.logOut();
      dispatch(resetPasswordError(storableError(e)));
    });
};
