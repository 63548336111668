import { types as sdkTypes } from './sdkLoader';
const { UUID, Money, LatLng } = sdkTypes
import Decimal from 'decimal.js';

const parseDataItem = (data) => {
    // console.log(data.type)
    data.id = new UUID(data.id);
    if (data.type === 'transaction') {
        data.attributes.payoutTotal = new Money(
            data.attributes.payoutTotal.amount,
            data.attributes.payoutTotal.currency
        );
        data.attributes.payinTotal = new Money(
            data.attributes.payinTotal.amount,
            data.attributes.payoutTotal.currency
        );
        data.attributes.lineItems.forEach(li => {
            li.unitPrice = new Money(li.unitPrice.amount, li.unitPrice.currency)
            li.lineTotal = new Money(li.lineTotal.amount, li.lineTotal.currency)
            if (li.quantity) {
                li.quantity = new Decimal(li.quantity);
            }
        })
    } else if (data.type === 'listing' || data.type === 'ownListing') {
        try {
            if (data.attributes.geolocation) {
                data.attributes.geolocation =
                    new LatLng(data.attributes.geolocation.lat, data.attributes.geolocation.lng)
            }
        } catch (e) { }

        try {
            if (data.attributes.price) {
                data.attributes.price = new Money(data.attributes.price.amount, data.attributes.price.currency)
            }
        } catch (e) { }

        try {
            if (data.attributes.publicData) {
                try {
                    if (data.attributes.publicData.per_person) {
                        data.attributes.publicData.per_person = new Money(data.attributes.publicData.per_person.amount,
                            data.attributes.publicData.per_person.currency);
                    }
                } catch (e) { }

                try {
                    if (data.attributes.publicData.mealFee) {
                        data.attributes.publicData.mealFee = new Money(data.attributes.publicData.mealFee.amount,
                            data.attributes.publicData.mealFee.currency);
                    }
                } catch (e) { }
                try {
                    if (data.attributes.publicData.ghost_kitchen) {
                        data.attributes.publicData.ghost_kitchen = new Money(data.attributes.publicData.ghost_kitchen.amount,
                            data.attributes.publicData.ghost_kitchen.currency);
                    }
                } catch (e) { }
            }

        } catch (e) { }


    }

    if (data.relationships) {
        const keys = Object.keys(data.relationships)
        keys.forEach(key => {
            if (data.relationships[key].data && data.relationships[key].data !== null) {
                try {
                    if (data.relationships[key].data.id) {
                        data.relationships[key].data.id = new UUID(data.relationships[key].data.id)
                    } else {
                        try {
                            data.relationships[key].data.forEach(dat => {
                                dat.id = new UUID(dat.id)
                            })
                        } catch (e) {

                        }

                    }
                } catch (e) {
                    console.log(e)
                }
            }
        })
    }
    return data;
}


const parseDataArray = (data) => {

    data.data.forEach(dat => {
        dat = parseDataItem(dat)
    })


    return data
}


const parseDataIncluded = (data) => {
    if (data.included) {
        data.included.forEach(dat => {
            dat = parseDataItem(dat)
        })
    }

    return data
}

export const parseData = (data) => {
    if (data.data.id) {
        data.data = parseDataItem(data.data)
    } else {
        data = parseDataArray(data)
    }

    data = parseDataIncluded(data)
    //console.log(JSON.stringify(data))
    return data
}










