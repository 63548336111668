import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
        <svg
        className={className}
        {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="450"
      height="100"
      version="1"
      viewBox="0 0 277.5 75"
    >
      <defs>
        <clipPath id="f5293125d3">
          <path d="M18.277 41H64v18H18.277zm0 0"></path>
        </clipPath>
        <clipPath id="3ca7932df6">
          <path d="M21 38h65.527v29.46H21zm0 0"></path>
        </clipPath>
        <clipPath id="4e687bd3ea">
          <path d="M22 4.46h33V54H22zm0 0"></path>
        </clipPath>
      </defs>
      <path
        fill="#ff5757"
        d="M22.012-19.402c6.707 0 8.101-6.211 7.425-11.16h-.988c0 1.98-.496 3.238-2.703 3.238-2.25 0-5.176-3.465-9.676-3.465C7.652-30.79.81-23.855.81-15.258.809-6.66 7.652.316 16.07.316c5.985 0 11.793-3.511 14.27-8.597l-.902-.496c-1.665 3.195-4.77 6.12-8.778 5.132-2.879-.675-6.3-3.917-9.273-8.687-4.184-6.754-5.489-13.504-2.88-15.125 4.548-2.883 6.84 8.055 13.505 8.055zm0 0"
        transform="translate(90.975 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M10.895 0v-15.98c.855-.18 1.71.046 2.746.543 1.035.539 2.023.988 3.015 1.124V0h9.856v-30.52h-9.856v15.172c-.722-.136-1.531-.543-2.523-1.035-.856-.453-1.98-.855-3.238-.676v-13.46h-9.86V0zm0 0"
        transform="translate(123.606 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M19.492-16.43h-5.851c-10.04 0-11.614-8.597-8.555-10.441 4.594-2.793 5.445 7.469 12.11 7.469 6.706 0 8.1-6.211 7.425-11.16h-.988c0 1.98-.496 3.238-2.703 3.238-2.25 0-5.356-3.465-9.813-3.465-12.691 0-10.89 12.559-1.125 15.125C4.68-14.72.81-12.422.81-8.06.809-3.152 5.94.316 14.402.316c6.078 0 9.364-2.027 12.067-5.043l-.496-.539C22.055-1.125 14.719.496 11.164-6.527c-2.746-5.356-1.262-8.73 3.422-8.73h4.14zm0 0"
        transform="translate(153.401 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M18.59-14.766h-7.742c-.63-5.129-8.059-4.77-8.461-7.562-.18-1.215.312-2.473 2.16-2.473 4.004 0 6.797 5.446 13.773 5.446 5.852 0 7.922-6.211 7.246-11.165h-.988c0 1.98-.855 3.243-2.836 3.243-4.187 0-6.574-3.602-12.246-3.602-4.453 0-8.46 2.07-8.46 9.813V0h9.859v-13.684h7.695zm0 0"
        transform="translate(181.261 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M11.523-14.719c8.372 0 .497 7.832 5.223 12.559C21.473 2.566 28.043-.855 31.06-4.816l-.72-.72c-1.398 1.438-2.925 1.981-4.503.403-1.574-1.574-1.078-5.265-4.23-8.418-1.079-1.035-2.114-1.664-3.196-1.98 4.774-1.078 7.88-3.465 7.88-7.2 0-5.269-6.216-8.101-14.63-8.101-4.457 0-10.625 2.023-10.625 9.766V0h9.86v-17.016c0-5.804-5.45-5.535-6.528-8.191-.449-1.125-.137-2.973 2.88-2.973 2.339 0 5.358 1.262 7.429 3.871 4.183 5.18 1.527 8.555-3.153 8.555zm0 0"
        transform="translate(210.022 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M13.367-30.52c5.09 3.106 5.54 7.383 2.703 12.29a8.304 8.304 0 00-.722 1.574c-.27-1.485-.762-3.149-1.618-5.895-2.207-6.887-7.52-8.281-9.812-8.281-2.344 0-5.223.852-7.023 3.375l.808.809c1.399-1.532 3.738-1.395 4.817 1.171.765 1.844.992 3.286 1.757 5.493 1.664 4.68 10.22.855 10.22 7.02 0 5.269-8.688 3.194-8.688 8.913V0h9.812v-12.828c0-.945.18-2.793 1.934-5.54 2.925-4.589 9.453-7.515 9.453-12.152zm0 0"
        transform="translate(239.367 51.223)"
      ></path>
      <g clipPath="url(#f5293125d3)">
        <path
          fill="#ff5757"
          d="M34.988 58.676c-9.629 0-15.476-2.528-16.675-7.313a.317.317 0 01.23-.383.32.32 0 01.383.23c1.347 5.38 9.176 7.704 22.047 6.548C52.625 56.71 60.32 52.484 62.539 49c.707-1.113.89-2.16.543-3.117-1.602-4.399-13.172-4.035-13.289-4.031a.318.318 0 01-.328-.305.314.314 0 01.305-.324c.496-.02 12.148-.38 13.906 4.445.414 1.133.203 2.402-.606 3.672-2.808 4.414-11.875 8.133-22.043 9.047-2.152.191-4.168.289-6.039.289"
        ></path>
      </g>
      <g clipPath="url(#3ca7932df6)">
        <path
          fill="#ff5757"
          d="M68.09 42.234c.3.98.73 2.524.89 3.836 2.114-.965 4.606-1.187 6.918-1.394 1.34-.117 2.602-.23 3.7-.48 4.773-1.075 6.226-2.262 5.988-4.891-.031-.328-.531-.48-.95-.555-3.296-.563-12.652 1.879-16.546 3.484zm-1.7.961c.465 1.543.926 2.782 1.301 3.602.223-.152.454-.293.692-.426-.13-1.195-.535-2.754-.875-3.883-.61.282-1.008.524-1.117.707zm1.59 4.184c.125.23.235.387.32.465.009-.016.016-.035.024-.055.067-.18.094-.418.098-.7-.152.09-.297.188-.442.29zM39.441 67.246c-.8 0-1.613-.035-2.433-.098-10.946-.851-15.363-8.421-15.406-8.496a.317.317 0 01.55-.313c.043.075 4.309 7.356 14.907 8.18 11.914.93 21.918-5.085 28.164-16.933a7.725 7.725 0 011.953-2.402c-.426-.868-.895-2.157-1.406-3.86-.067-.215.02-.433.25-.648.222-.207.68-.465 1.312-.746l-.144-.434a.314.314 0 01.195-.398.314.314 0 01.402.195c.024.066.07.2.13.387 4.41-1.817 14.628-4.465 17.452-3.387.653.25.82.656.848.953.308 3.383-2.04 4.566-6.477 5.566-1.14.254-2.425.372-3.785.493-2.324.207-4.84.43-6.91 1.445.023.5-.012.934-.125 1.254-.16.434-.406.516-.594.512-.254-.004-.531-.262-.84-.762a7.104 7.104 0 00-1.703 2.125c-5.941 11.273-15.234 17.367-26.34 17.367"
        ></path>
      </g>
      <g clipPath="url(#4e687bd3ea)">
        <path
          fill="#ff5757"
          d="M39.355 17.781c-.94 0-1.886.121-2.804.364-1.367.363-2.168 1.093-2.2 2.003-.035 1.094 1.02 2.223 2.563 2.739 2.902.972 5.879.547 8.61-1.235a8.09 8.09 0 001.261-1.011l-.004-.004c-1.945-1.844-4.672-2.856-7.426-2.856zm5.067 8.305c-4.293 0-9.172 1.144-12.856 3.098-2.304 1.218-3.851 3.168-3.757 4.734.066 1.125.937 2.016 2.586 2.648 2.03.786 3.847.594 5.773.387 4.3-.46 8.062-2.36 10.586-5.348 1.07-1.27 1.762-3.117 1.926-5a8.935 8.935 0 00-1.575-.355 21.206 21.206 0 00-2.683-.164zM25.844 53.555h-.032c-2.375-.23-3.144-1.387-3.375-2.317-.48-1.957 1.063-4.484 2.805-5.617 4.531-2.95 8.942-3.89 14.047-4.984l2.172-.469.144-.035c4.235-.938 13.047-2.887 12.141-8.574-.355-2.22-2.305-3.891-4.473-4.743-.191 1.965-.921 3.875-2.035 5.196-2.633 3.113-6.539 5.09-11.004 5.566-2.004.215-3.894.418-6.07-.422-2.363-.914-2.926-2.242-2.984-3.199-.125-2.11 2.03-4.242 4.09-5.332 4.585-2.43 10.984-3.633 15.918-3a9.56 9.56 0 011.527.332c.035-1.789-.422-3.562-1.5-4.855a8.509 8.509 0 01-1.344 1.078c-2.898 1.89-6.066 2.34-9.156 1.304-1.836-.613-3.04-1.964-2.992-3.359.035-1.203 1.007-2.148 2.664-2.59 3.808-1.012 8.058.024 10.824 2.64a6.118 6.118 0 001.086-1.827c.984-2.672-2.09-5.563-4.262-6.762a73.99 73.99 0 01-.765-.418c-1.176-.637-2.391-1.297-3.5-2.102-.723-.523-1.946-1.582-2.06-2.812-.054-.594.157-1.149.626-1.64a.32.32 0 01.45-.009c.124.122.128.32.007.446-.34.36-.488.734-.453 1.148.094 1.016 1.289 1.989 1.8 2.36 1.075.78 2.274 1.43 3.43 2.058.258.137.516.278.77.418 2.355 1.297 5.672 4.488 4.55 7.531a6.844 6.844 0 01-1.242 2.07c1.247 1.466 1.75 3.497 1.672 5.524 2.418.906 4.645 2.781 5.047 5.3 1 6.27-8.203 8.31-12.625 9.29l-.144.031c-.739.164-1.465.32-2.18.473-5.043 1.078-9.398 2.012-13.832 4.894-1.527.993-2.945 3.27-2.531 4.942.254 1.047 1.23 1.683 2.816 1.836a.315.315 0 01-.027.629"
        ></path>
      </g>
      <path
        fill="#ff5757"
        d="M37.3 52.465a.313.313 0 01-.312-.313.312.312 0 01.313-.316c4.39-.016 8.07-.563 11.75-3.211a.318.318 0 01.441.074.31.31 0 01-.074.438c-3.816 2.746-7.602 3.316-12.113 3.328H37.3M34.496 52.465h-1.64a.314.314 0 010-.63h1.64c.172 0 .316.142.316.313a.318.318 0 01-.316.317M58.36 50.598a.324.324 0 01-.282-.168.316.316 0 01.137-.426c1.469-.762 3.043-2.04 2.2-3.785a.318.318 0 01.148-.422.32.32 0 01.421.148c.828 1.72-.05 3.36-2.48 4.617a.316.316 0 01-.145.036"
      ></path>
    </svg>
    );
  }

  return (
    <svg
        className={className}
        {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="230"
      height="100"
      version="1"
      viewBox="0 0 277.5 75"
    >
      <defs>
        <clipPath id="f5293125d3">
          <path d="M18.277 41H64v18H18.277zm0 0"></path>
        </clipPath>
        <clipPath id="3ca7932df6">
          <path d="M21 38h65.527v29.46H21zm0 0"></path>
        </clipPath>
        <clipPath id="4e687bd3ea">
          <path d="M22 4.46h33V54H22zm0 0"></path>
        </clipPath>
      </defs>
      <path
        fill="#ff5757"
        d="M22.012-19.402c6.707 0 8.101-6.211 7.425-11.16h-.988c0 1.98-.496 3.238-2.703 3.238-2.25 0-5.176-3.465-9.676-3.465C7.652-30.79.81-23.855.81-15.258.809-6.66 7.652.316 16.07.316c5.985 0 11.793-3.511 14.27-8.597l-.902-.496c-1.665 3.195-4.77 6.12-8.778 5.132-2.879-.675-6.3-3.917-9.273-8.687-4.184-6.754-5.489-13.504-2.88-15.125 4.548-2.883 6.84 8.055 13.505 8.055zm0 0"
        transform="translate(90.975 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M10.895 0v-15.98c.855-.18 1.71.046 2.746.543 1.035.539 2.023.988 3.015 1.124V0h9.856v-30.52h-9.856v15.172c-.722-.136-1.531-.543-2.523-1.035-.856-.453-1.98-.855-3.238-.676v-13.46h-9.86V0zm0 0"
        transform="translate(123.606 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M19.492-16.43h-5.851c-10.04 0-11.614-8.597-8.555-10.441 4.594-2.793 5.445 7.469 12.11 7.469 6.706 0 8.1-6.211 7.425-11.16h-.988c0 1.98-.496 3.238-2.703 3.238-2.25 0-5.356-3.465-9.813-3.465-12.691 0-10.89 12.559-1.125 15.125C4.68-14.72.81-12.422.81-8.06.809-3.152 5.94.316 14.402.316c6.078 0 9.364-2.027 12.067-5.043l-.496-.539C22.055-1.125 14.719.496 11.164-6.527c-2.746-5.356-1.262-8.73 3.422-8.73h4.14zm0 0"
        transform="translate(153.401 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M18.59-14.766h-7.742c-.63-5.129-8.059-4.77-8.461-7.562-.18-1.215.312-2.473 2.16-2.473 4.004 0 6.797 5.446 13.773 5.446 5.852 0 7.922-6.211 7.246-11.165h-.988c0 1.98-.855 3.243-2.836 3.243-4.187 0-6.574-3.602-12.246-3.602-4.453 0-8.46 2.07-8.46 9.813V0h9.859v-13.684h7.695zm0 0"
        transform="translate(181.261 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M11.523-14.719c8.372 0 .497 7.832 5.223 12.559C21.473 2.566 28.043-.855 31.06-4.816l-.72-.72c-1.398 1.438-2.925 1.981-4.503.403-1.574-1.574-1.078-5.265-4.23-8.418-1.079-1.035-2.114-1.664-3.196-1.98 4.774-1.078 7.88-3.465 7.88-7.2 0-5.269-6.216-8.101-14.63-8.101-4.457 0-10.625 2.023-10.625 9.766V0h9.86v-17.016c0-5.804-5.45-5.535-6.528-8.191-.449-1.125-.137-2.973 2.88-2.973 2.339 0 5.358 1.262 7.429 3.871 4.183 5.18 1.527 8.555-3.153 8.555zm0 0"
        transform="translate(210.022 51.223)"
      ></path>
      <path
        fill="#ff5757"
        d="M13.367-30.52c5.09 3.106 5.54 7.383 2.703 12.29a8.304 8.304 0 00-.722 1.574c-.27-1.485-.762-3.149-1.618-5.895-2.207-6.887-7.52-8.281-9.812-8.281-2.344 0-5.223.852-7.023 3.375l.808.809c1.399-1.532 3.738-1.395 4.817 1.171.765 1.844.992 3.286 1.757 5.493 1.664 4.68 10.22.855 10.22 7.02 0 5.269-8.688 3.194-8.688 8.913V0h9.812v-12.828c0-.945.18-2.793 1.934-5.54 2.925-4.589 9.453-7.515 9.453-12.152zm0 0"
        transform="translate(239.367 51.223)"
      ></path>
      <g clipPath="url(#f5293125d3)">
        <path
          fill="#ff5757"
          d="M34.988 58.676c-9.629 0-15.476-2.528-16.675-7.313a.317.317 0 01.23-.383.32.32 0 01.383.23c1.347 5.38 9.176 7.704 22.047 6.548C52.625 56.71 60.32 52.484 62.539 49c.707-1.113.89-2.16.543-3.117-1.602-4.399-13.172-4.035-13.289-4.031a.318.318 0 01-.328-.305.314.314 0 01.305-.324c.496-.02 12.148-.38 13.906 4.445.414 1.133.203 2.402-.606 3.672-2.808 4.414-11.875 8.133-22.043 9.047-2.152.191-4.168.289-6.039.289"
        ></path>
      </g>
      <g clipPath="url(#3ca7932df6)">
        <path
          fill="#ff5757"
          d="M68.09 42.234c.3.98.73 2.524.89 3.836 2.114-.965 4.606-1.187 6.918-1.394 1.34-.117 2.602-.23 3.7-.48 4.773-1.075 6.226-2.262 5.988-4.891-.031-.328-.531-.48-.95-.555-3.296-.563-12.652 1.879-16.546 3.484zm-1.7.961c.465 1.543.926 2.782 1.301 3.602.223-.152.454-.293.692-.426-.13-1.195-.535-2.754-.875-3.883-.61.282-1.008.524-1.117.707zm1.59 4.184c.125.23.235.387.32.465.009-.016.016-.035.024-.055.067-.18.094-.418.098-.7-.152.09-.297.188-.442.29zM39.441 67.246c-.8 0-1.613-.035-2.433-.098-10.946-.851-15.363-8.421-15.406-8.496a.317.317 0 01.55-.313c.043.075 4.309 7.356 14.907 8.18 11.914.93 21.918-5.085 28.164-16.933a7.725 7.725 0 011.953-2.402c-.426-.868-.895-2.157-1.406-3.86-.067-.215.02-.433.25-.648.222-.207.68-.465 1.312-.746l-.144-.434a.314.314 0 01.195-.398.314.314 0 01.402.195c.024.066.07.2.13.387 4.41-1.817 14.628-4.465 17.452-3.387.653.25.82.656.848.953.308 3.383-2.04 4.566-6.477 5.566-1.14.254-2.425.372-3.785.493-2.324.207-4.84.43-6.91 1.445.023.5-.012.934-.125 1.254-.16.434-.406.516-.594.512-.254-.004-.531-.262-.84-.762a7.104 7.104 0 00-1.703 2.125c-5.941 11.273-15.234 17.367-26.34 17.367"
        ></path>
      </g>
      <g clipPath="url(#4e687bd3ea)">
        <path
          fill="#ff5757"
          d="M39.355 17.781c-.94 0-1.886.121-2.804.364-1.367.363-2.168 1.093-2.2 2.003-.035 1.094 1.02 2.223 2.563 2.739 2.902.972 5.879.547 8.61-1.235a8.09 8.09 0 001.261-1.011l-.004-.004c-1.945-1.844-4.672-2.856-7.426-2.856zm5.067 8.305c-4.293 0-9.172 1.144-12.856 3.098-2.304 1.218-3.851 3.168-3.757 4.734.066 1.125.937 2.016 2.586 2.648 2.03.786 3.847.594 5.773.387 4.3-.46 8.062-2.36 10.586-5.348 1.07-1.27 1.762-3.117 1.926-5a8.935 8.935 0 00-1.575-.355 21.206 21.206 0 00-2.683-.164zM25.844 53.555h-.032c-2.375-.23-3.144-1.387-3.375-2.317-.48-1.957 1.063-4.484 2.805-5.617 4.531-2.95 8.942-3.89 14.047-4.984l2.172-.469.144-.035c4.235-.938 13.047-2.887 12.141-8.574-.355-2.22-2.305-3.891-4.473-4.743-.191 1.965-.921 3.875-2.035 5.196-2.633 3.113-6.539 5.09-11.004 5.566-2.004.215-3.894.418-6.07-.422-2.363-.914-2.926-2.242-2.984-3.199-.125-2.11 2.03-4.242 4.09-5.332 4.585-2.43 10.984-3.633 15.918-3a9.56 9.56 0 011.527.332c.035-1.789-.422-3.562-1.5-4.855a8.509 8.509 0 01-1.344 1.078c-2.898 1.89-6.066 2.34-9.156 1.304-1.836-.613-3.04-1.964-2.992-3.359.035-1.203 1.007-2.148 2.664-2.59 3.808-1.012 8.058.024 10.824 2.64a6.118 6.118 0 001.086-1.827c.984-2.672-2.09-5.563-4.262-6.762a73.99 73.99 0 01-.765-.418c-1.176-.637-2.391-1.297-3.5-2.102-.723-.523-1.946-1.582-2.06-2.812-.054-.594.157-1.149.626-1.64a.32.32 0 01.45-.009c.124.122.128.32.007.446-.34.36-.488.734-.453 1.148.094 1.016 1.289 1.989 1.8 2.36 1.075.78 2.274 1.43 3.43 2.058.258.137.516.278.77.418 2.355 1.297 5.672 4.488 4.55 7.531a6.844 6.844 0 01-1.242 2.07c1.247 1.466 1.75 3.497 1.672 5.524 2.418.906 4.645 2.781 5.047 5.3 1 6.27-8.203 8.31-12.625 9.29l-.144.031c-.739.164-1.465.32-2.18.473-5.043 1.078-9.398 2.012-13.832 4.894-1.527.993-2.945 3.27-2.531 4.942.254 1.047 1.23 1.683 2.816 1.836a.315.315 0 01-.027.629"
        ></path>
      </g>
      <path
        fill="#ff5757"
        d="M37.3 52.465a.313.313 0 01-.312-.313.312.312 0 01.313-.316c4.39-.016 8.07-.563 11.75-3.211a.318.318 0 01.441.074.31.31 0 01-.074.438c-3.816 2.746-7.602 3.316-12.113 3.328H37.3M34.496 52.465h-1.64a.314.314 0 010-.63h1.64c.172 0 .316.142.316.313a.318.318 0 01-.316.317M58.36 50.598a.324.324 0 01-.282-.168.316.316 0 01.137-.426c1.469-.762 3.043-2.04 2.2-3.785a.318.318 0 01.148-.422.32.32 0 01.421.148c.828 1.72-.05 3.36-2.48 4.617a.316.316 0 01-.145.036"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
