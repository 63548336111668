import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { LINE_ITEM_CUSTOMER_COMMISSION, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl, pageData,unitType } = props;

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  const customerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
  );

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );


  const per_person = pageData?.listing?.attributes?.publicData?.per_person;
  const ghost_kitchen = pageData?.listing?.attributes?.publicData?.ghost_kitchen;

  const quantity =
    pageData?.bookingData?.host_type === 'catering'
      ? pageData?.bookingData?.quantity
      : unitPurchase
      ? unitPurchase.quantity.toString()
      : null;

  let amount =
    pageData?.bookingData?.host_type === 'catering'
      ? per_person.amount * +quantity + +customerCommissionLineItem?.lineTotal?.amount
      : pageData?.bookingData?.host_type === 'ghost_kitchen'
      ? ghost_kitchen.amount * +quantity + +customerCommissionLineItem?.lineTotal?.amount
      : null;

  const totalPriceD =
    pageData?.bookingData?.host_type === 'catering'
      ? formatMoney(intl, new Money(amount, per_person.currency))
      : pageData?.bookingData?.host_type === 'ghost_kitchen'
      ? formatMoney(intl, new Money(amount, ghost_kitchen.currency))
      : null;
  // ? transaction.attributes.payoutTotal
  // : transaction.attributes.payinTotal

  const formattedTotalPrice =
    (pageData?.bookingData?.host_type === 'catering' || pageData?.bookingData?.host_type === 'ghost_kitchen' ) ? totalPriceD : formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
